import React, {useState} from "react";
import {Link} from "react-router-dom";
import {ReactComponent as BackSvg} from "../../images/back.svg";
import {useParams} from "react-router";
import {ReactComponent as PaySvg} from "../../images/pay.svg";
import {ReactComponent as OrderOpenSvg} from "../../images/order-open.svg";
import {ReactComponent as OrderDateSvg} from "../../images/order-date.svg";
import {Map, Placemark, YMaps} from "@pbe/react-yandex-maps";
import {ProfileBlockSm} from "../../components/ProfileBlockSm";
import {ReactComponent as OrderClosedSvg} from "../../images/order-closed.svg";
import {OrderReview} from "../OrderReview";

export function OrderItem(props) {
    let { orderId} = useParams();
    const [orderData, updateOrderData] = useState(false);
    const [activeTab, updateActiveTab] = useState(props.selectedTab);
    const [isReady, updateReadyState] = useState(false);
    const [inActionState, updateInActionState] = useState(false);

    const apiUrl = 'https://api.promarena.ru/rest/?action=getOrder&orderId='+orderId;



    if(!isReady) {
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                console.log('This is your data', data);
                if (data.success) {
                    console.log("success");
                    updateOrderData(data.order);
                    updateReadyState(true);
                }
            });
    }

    const defaultState = {
        center: [55.751574, 37.573856],
        zoom: 5,
    };

    function customerAction(status) {
        updateInActionState(true);
        console.dir("status:"+status);
        const apiUrl = 'https://api.promarena.ru/rest/?action=setStatus&orderId='+orderId+"&status="+status;
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                console.log('This is your data', data);
                updateReadyState(false);
                updateInActionState(false);
            });
    }

    return (
        <React.Fragment>
            <div className='page-header'>
                <Link className='page-header-nav' to={"/orders/"}>
                    <div className='nav-rounded-bg'>
                        <BackSvg />
                    </div>

                </Link>
                <div className='page-header-title'>
                    Заявка №{orderId}
                </div>
                <div className='page-header-nav'>

                </div>
            </div>

            <div className='tabs'>
                <Link to={"/order/"+orderId+"/"} className={activeTab == 'detail' ? 'tab active' : 'tab'} onClick={()=>updateActiveTab('detail')}>Детали</Link>
                <Link to={"/order/"+orderId+"/payment/"} className={activeTab == 'payments' ? 'tab active' : 'tab'} onClick={()=>updateActiveTab('payments')}>Финансы</Link>
                <Link to={"/order/"+orderId+"/status/"} className={activeTab == 'status' ? 'tab active' : 'tab'} onClick={()=>updateActiveTab('status')}>Статус</Link>
                {((orderData?.status?.CODE == "WORK_PAID") || (orderData?.status?.CODE == "DONE"))&&
                <Link to={"/order/"+orderId+"/review/"} className={activeTab == 'review' ? 'tab active' : 'tab'} onClick={()=>updateActiveTab('review')}>Отзыв</Link>
                }
            </div>



            <div className={(activeTab == 'detail') ? 'tab-content active' : 'tab-content'}>
                <div className='content-wrapper'>
                    <div className='content-profile'>
                        {((orderData != false) && (orderData.offer != false)) &&
                            <div>
                                <ProfileBlockSm profile={orderData.offer.EXECUTOR} />
                            </div>
                        }

                    </div>
                </div>

                <YMaps >
                    <Map width="100vw"
                         height="30vh"
                         defaultState={defaultState}>
                        <Placemark geometry={[55.684758, 37.738521]} />
                    </Map>
                </YMaps>

                <div className='content-wrapper'>
                    <div>
                    Карта
                    </div>
                    <div className='order-field'>
                        <div className='icon info'>
                            <OrderDateSvg/>
                        </div>
                        <div>
                            <div><b>{orderData?.UF_ORDER_TO_DATE}</b></div>
                            <div className='label'>Дата заказа</div>
                        </div>
                    </div>


                        {(orderData?.status?.CODE == 'DONE') ? (
                            <div className='order-field'>
                                <div className='icon warning'>
                                    <OrderClosedSvg/>
                                </div>
                                <div>
                                    <div><b>Завершено</b></div>
                                </div>

                            </div>
                        ) : (
                            <div className='order-field'>
                                <div className='icon success'>
                                    <OrderOpenSvg/>
                                </div>
                                <div>
                                <div><b>Открыта</b></div>
                                    <div className='label'>{orderData?.status?.TITLE}</div>
                                </div>
                            </div>
                        )}



                    <div>
                        <div>Комментарий заказчика</div>
                        <div>{orderData.UF_DESCRIPTION}</div>
                    </div>

                </div>
            </div>

            <div className={(activeTab == 'payments') ? 'tab-content active' : 'tab-content'}>
                <React.Fragment>
                    {((orderData != false) && (orderData.offer != false)) &&
                        <React.Fragment>
                            <div className='price content-wrapper'>
                                <div className='item-title'>Основные услуги</div>
                                <div className='item'>
                                    <div className='item-name'>Работа</div>
                                    <div className='item-value'>{Intl.NumberFormat('ru-RU').format(orderData.offer.UF_PRICE)}&nbsp;₽</div>
                                </div>
                                <div className='item'>
                                    <div className='item-name'>Дорога</div>
                                    <div className='item-value'>{Intl.NumberFormat('ru-RU').format(orderData.offer.UF_ROAD_PRICE)}&nbsp;₽</div>
                                </div>

                            </div>
                            <div className='sep'></div>
                            <div className='price content-wrapper'>
                                <div className='item-title'>Комиссия и сборы</div>
                                <div className='item'>
                                    <div className='item-name'>Комиссия</div>
                                    <div className='item-value'>{Intl.NumberFormat('ru-RU').format(orderData.offer.COMMISSION)}&nbsp;₽</div>
                                </div>
                            </div>
                            <div className='sep'></div>
                            <div className='content-wrapper mt-10'>
                                <div className='order-field'>
                                    <div className='icon success'>
                                        <PaySvg/>
                                    </div>
                                    <div>
                                        <div className='price-total'><b>{Intl.NumberFormat('ru-RU').format(orderData.offer.PRICE_TOTAL)}&nbsp;₽</b></div>
                                        <div className='label price-label'>Общая стоимость</div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>

                    }

                </React.Fragment>
            </div>

            <div className={(activeTab == 'status') ? 'tab-content active' : 'tab-content'}>
                <React.Fragment>
                    <div className='content-wrapper'>
                        <div className='item-title'>Управление статусом</div>
                    </div>


                    <div className='content-wrapper'>
                        {(orderData != false) &&
                            <div className='order-history'>
                            {
                                orderData.history.map(orderItem => (
                                    <div key={orderItem.ID} className='order-history-item'>
                                        <b>{orderItem.UF_STATUS_TITLE}</b>
                                        <span>{orderItem.DATETIME}</span>
                                    </div>
                                ))
                            }
                            </div>
                        }
                    </div>


                    <div className='sep'></div>
                    <div className='content-wrapper'>
                        <Link to={"/support/"+orderId+"/"}>Написать в поддержку</Link>
                    </div>
                </React.Fragment>
            </div>

            <div className={(activeTab == 'review') ? 'tab-content active' : 'tab-content'}>
                {((orderData != false) && (orderData.offer != false)) &&

                            <OrderReview
                                orderId={orderId}
                                executorPhone={orderData?.offer?.EXECUTOR.PHONE}
                                executorReview={orderData?.reviews?.executor}
                                orderStatus={orderData?.status?.CODE}
                                onReviewSend={()=>customerAction("DONE")}
                            />



                }
            </div>

            {orderData?.status?.CUSTOMER_CONFIRM &&
                <div className='footer action'>
                    {(inActionState == false) ? (
                        <div className="btn btn-main" onClick={()=>customerAction(orderData?.status?.NEXT)}>{orderData?.status?.CUSTOMER_CONFIRM_TEXT}</div>
                    ) : (
                        <div className="btn btn-main" >Отправка данных...</div>
                    )}

                </div>
            }
            {((orderData?.status?.CODE == "WORK_PAID") && ((activeTab != 'review')))&&
            <div className='footer action'>

                <Link className="btn btn-main" onClick={()=>updateActiveTab('review')} to={"/order/"+orderId+"/review/"}>Оставить отзыв</Link>





            </div>
            }

        </React.Fragment>
    )
}