import React, {useState} from "react";
import {InputSelector} from "../../components/InputSelector";

import { ReactComponent as CalendarSvg } from '../../images/calendar.svg';
import { ReactComponent as ClockSvg } from '../../images/clock.svg';
import { ReactComponent as MarkerSvg } from '../../images/marker.svg';
import {Link} from "react-router-dom";
import {ReactComponent as BackSvg} from "../../images/back.svg";
import {useParams} from "react-router";


export function Settings(props) {

    let { orderId } = useParams();

    const [messageText, updateMessageText] = useState('');
    const [messageList, updateMessageList] = useState([]);
    const [isReady, updateReadyState] = useState(false);

    const apiUrl = 'https://api.promarena.ru/rest/?action=getSupportChat&orderId='+orderId;

    console.log("PROPS");
    console.dir(props.user);



    return (
        <div className='page'>
            <div className='page-header'>
                <Link className='page-header-nav' to={orderId ? '/orders/' : '/'} >
                    <div className='nav-rounded-bg'>
                        <BackSvg />
                    </div>

                </Link>
                <div className='page-header-title'>
                    <span>Настройки</span>
                    {orderId &&
                        <span>&nbsp;по заказу #{orderId}</span>
                    }

                </div>
                <div className='page-header-nav'>

                </div>
            </div>
            <div className='message-list'>

                ...

            </div>

        </div>
    )
}