import React, {useState} from "react";

export function Uploader(props) {

    const maxUploadFileSize = 1024 * 1024 * 0.5;
    const [uploadList, updateUploadList] = useState([]);
    const [inUploadState, updateInUploadState] = useState(0);
    const [orderTempId,updateOrderTempId] = useState(props.orderTempId);
    if(props.orderTempId != orderTempId) {
        updateUploadList([]);
        updateOrderTempId(props.orderTempId);
    }
    function uploadFile(file) {

        if(file.size > maxUploadFileSize) {
            console.log("File Size Exceeded");
            return false;
        }

        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onprogress = function (data) {
            if (data.lengthComputable) {
                let progress = parseInt( ((data.loaded / data.total) * 100), 10 );
                console.log("progress:"+progress);
            }
        }

        reader.onload = function() {
            console.log("SUCCESS!!!");


            const apiUrl = 'https://api.promarena.ru/rest/upload.php';
            fetch(apiUrl,
                {
                    method: 'POST',
                    headers: new Headers(
                        {"Content-Type": "application/json",
                            "Accept":"application/json"}
                    ),
                    body: JSON.stringify(
                        {'order_temp_id':props.orderTempId,'file': reader.result}
                    )
                }
            )
            .then((response) => response.json())
            .then((data) => {
                console.log('After upload send this is your data', data);
                updateUploadList(prevState => {
                    let newState = [...prevState];

                    newState.unshift(reader.result);
                    updateInUploadState(prevState => prevState-1);

                    return newState;
                })
            });

            console.log("uploaded!!!");

            console.log(reader);
        };

        reader.onerror = function() {
            console.log(reader.error);
        };


        console.dir(file);
    }

    function getFiles(e) {
        if(e.target.files.length + uploadList.length > 10) {
            alert("Загрузка до 10 фотографий");
            return false;
        }
        console.log("START UPLOAD! ");
        updateInUploadState(e.target.files.length);
        return Promise.all(Array.from(e.target.files).map(uploadFile))
    }

    return (
        <div className="uploader">
            <span>Добавить фото/видео</span>
            <div className='uploader-container'>
                {(uploadList.length < 10) &&
                <label className="uploader-file-upload">
                    +
                    <input type='file' multiple={true} onChange={e => getFiles(e)}/>
                </label>
                }
                <div className='uploader-list'>
                    {uploadList.map((item,key) =>
                        <img key={key} src={item} />
                    )}
                </div>
            </div>
            <div className='uploader-state'>
            {(inUploadState > 0) &&
                <spap>Загрузка файлов: {inUploadState} шт</spap>
            }
            </div>
        </div>
    )
}