import React from "react";

export function ProfileBlockSm(props) {
    return (
        <div className='field-item'>
            <div className='icon'>
                {(props.profile.PHOTO != false) ?
                    (
                        <img src={props.profile.PHOTO.URL} />
                    ) : (
                        <div className='latter'>
                            {props.profile.NAME.charAt(0)}
                        </div>
                    )

                }
            </div>
            <div className='content'>
                <div className='value'>{props.profile.NAME}</div>
                {(props.profile.RATE > 0) ? (
                    <div className='label'>{props.profile.RATE} по {props.profile.RATE_COUNT} отзыву(ам)</div>
                ) : (
                    <div className='label'>Нет отзывов</div>
                )}



            </div>
        </div>
    )
}