import {Link} from "react-router-dom";
import {ReactComponent as BackSvg} from "../../images/back.svg";
import React, {useState} from "react";
import {ReactComponent as CalendarSvg} from "../../images/calendar.svg";
import {InputField, InputSelector} from "../../components/InputSelector";

export function Personal(props) {

    const [userName, updateUserName] = useState(props.user?.name ? props.user?.name : '');
    const [userNameSecond, updateUserNameSecond] = useState(props.user?.nameSecond ? props.user?.nameSecond : '');
    const [userMail, updateUserMail] = useState(props.user?.mail ? props.user?.mail : '');
    const [hasChanges, setHasChanges] = useState(false);
    const [isLoaded, setLoaded] = useState(false);
    const [mailValid, setMailValid] = useState(true);
    if(!isLoaded && props.user?.isAuthorized) {

        const apiUrl = 'https://api.promarena.ru/rest/?action=getUser';
        fetch(apiUrl,{
            method: 'POST',
            headers: new Headers(
                {"Content-Type": "application/json",
                    "Accept":"application/json"}
            ),
            body: JSON.stringify(
                {'userPhone': props.user.phone}
            )})
            .then((response) => response.json())
            .then((res) => {
                console.log('This is your user '+props.user.phone+' data', res);
                if (res.ok) {
                    //
                    console.log("loaded!!!");
                    updateUserName(res['data'].UF_NAME);
                    updateUserNameSecond(res['data'].UF_NAME_SECOND);
                    updateUserMail(res['data'].UF_MAIL)
                    setLoaded(true);
                } else {
                    setLoaded( true);
                }
            });

    }

    function saveUser() {
        console.log("saveUser");
        const apiUrl = 'https://api.promarena.ru/rest/?action=updateUser';
        fetch(apiUrl,{
            method: 'POST',
            headers: new Headers(
                {"Content-Type": "application/json",
                    "Accept":"application/json"}
            ),
            body: JSON.stringify(
                {
                    'userPhone': props.user.phone,
                    'userName' : userName,
                    'userNameSecond' : userNameSecond,
                    'userMail' : props.user.phone
                }
            )})
            .then((response) => response.json())
            .then((res) => {
                console.log('This is your user '+props.user.phone+' data', res);
                if (res.ok) {
                    //
                    console.log("updated!!!");
                    setHasChanges(false);
                    window.location.href = "/";
                }
            });
    }
    const regCyr = /[^а-яёa-z]/i;

    let handleOnChangeMail = ( email ) => {
        if(email.length == 0) {
            setMailValid(true);
        } else {
            // don't remember from where i copied this code, but this works.
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(email)) {
                setMailValid(true);
            } else {
                setMailValid(true);
            }
        }
    }

    return (
        <div className='page'>
            <div className='page-header'>
                {(props?.reg !== true) &&
                <Link className='page-header-nav' to='/'>

                    <div className='nav-rounded-bg'>
                        <BackSvg/>
                    </div>

                </Link>
                }
                <div className='page-header-title'>
                    {props?.title ? props.title : 'Личный кабинет'}


                </div>
                <div className='page-header-nav'>

                </div>
            </div>
            {isLoaded ? (
                <div className='input-field-container'>

                    <InputField title='Имя' type='text'
                                value={userName}
                                onChange={(event)=>{
                                    updateUserName(event.target.value.replace(regCyr, ""));
                                    setHasChanges(true);
                                }}
                    />
                    <InputField title='Фамилия' type='text' icon={<CalendarSvg />}
                                value={userNameSecond}
                                onChange={(event)=>{
                                    updateUserNameSecond(event.target.value.replace(regCyr, ""));
                                    setHasChanges(true);
                                }}
                    />
                    <InputField title='E-mail' type='text' icon={<CalendarSvg />}
                                value={props.user.phone}
                                readonly={true}
                                onChange={(event)=>{
                                    updateUserMail(event.target.value);
                                    handleOnChangeMail(event.target.value);
                                    setHasChanges(true);
                                }}
                    />
                    {(mailValid == false) &&
                        <span className='red'>Неверный email!</span>
                    }
                </div>
            ) : (
                <div>Загрузка...</div>
            )}

            {((userName.length > 1) && hasChanges && mailValid) &&
                <div className="footer action">
                    <div className="col">
                        <div className='btn btn-main' onClick={saveUser}>Подтвердить</div></div>
                </div>
            }
        </div>
    )
}