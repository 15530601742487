import {Link} from "react-router-dom";
import {ReactComponent as BackSvg} from "../../images/back.svg";
import React, {useState} from "react";
import {ReactComponent as CalendarSvg} from "../../images/calendar.svg";
import {InputSelector} from "../../components/InputSelector";

export function ExecutorFilter(props) {
    const [addr, setAddr] = useState("");
    const [isChange, setChange] = useState(false);
    const [isReady, updateReadyState] = useState(false);


    const [cityList,updateCityList] = useState([]);
    if(cityList.length == 0) {
        const apiUrl = 'https://api.promarena.ru/rest/?action=getCityList';
        fetch(apiUrl)
            .then((response) => response.json())
            .then((rs) => {
                console.log('This is your type list data', rs);
                if(rs.data.list !== undefined) {
                    updateCityList(rs.data.list);
                } else {
                    console.warn("Something wrong goes!");
                }
            });
    }
    if(!isReady) {
        const apiUrl = 'https://api.promarena.ru/rest/?action=getExecutorFilter';
        const telegramId = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id;
        fetch(apiUrl,{
                method: 'POST',
                headers: new Headers(
                    {"Content-Type": "application/json",
                        "Accept":"application/json"}
                ),
                body: JSON.stringify(
                    {'telegramId': telegramId}
                )
            }
        )
            .then((response) => response.json())
            .then((data) => {
                console.log('This is your data', data);
                if (data.success) {
                    console.log("success!");
                    console.dir(data);
                    updateReadyState(true);
                    setAddr(data.addr);
                }
            });
    }
    function save() {
        const apiUrl = 'https://api.promarena.ru/rest/?action=saveExecutorFilter';
        const telegramId = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id;
        fetch(apiUrl,{
                method: 'POST',
                headers: new Headers(
                    {"Content-Type": "application/json",
                        "Accept":"application/json"}
                ),
                body: JSON.stringify(
                    {'telegramId': telegramId, 'addr': addr}
                )
            }
        )
            .then((response) => response.json())
            .then((data) => {
                console.log('This is your data', data);
                if (data.success) {
                    console.log("success!");
                    console.dir(data);
                    setChange(false);
                }
            });
    }
    return (
        <div className='page'>
            <div className='page-header'>
                <Link className='page-header-nav' to='/executor/list/'>
                    <div className='nav-rounded-bg'>
                        <BackSvg />
                    </div>

                </Link>
                <div className='page-header-title'>
                   Фильтра заявок

                </div>
                <div className='page-header-nav'>

                </div>
            </div>
            <div className="input-field-container">
                <input list="city" className='input-addr' placeholder='Введите адрес'
                       value={addr}
                       onChange={(event) => {
                           console.log("addr:"+event.target.value);
                           const apiUrl = 'https://api.promarena.ru/rest/?action=suggestAddress';
                           fetch(apiUrl,
                               {
                                   method: 'POST',
                                   headers: new Headers(
                                       {"Content-Type": "application/json",
                                           "Accept":"application/json"}
                                   ),
                                   body: JSON.stringify(
                                       {'q':event.target.value}
                                   )
                               }
                           )
                               .then((response) => response.json())
                               .then((data) => {
                                   console.log("address");
                                   console.dir(data)
                                   //setSuggestAddrList(data);
                               });
                           setAddr(event.target.value);
                           setChange(true);
                       }}
                />

                {cityList !== false &&
                    <datalist id="city">
                        {cityList.map(item => (
                            <option key={item.ID} value={item.UF_NAME}></option>
                        ))}
                    </datalist>
                }

            </div>
            {isChange &&
            <div className="footer action">
                <div className="col">
                    <div className='btn btn-main' onClick={save}>Сохранить</div>
                </div>
            </div>
            }
        </div>
    )
}