import React, {useState} from "react";
import {Link} from "react-router-dom";


export function NotifyItem(props) {
    const [marginLeft, setMarginLeft] = useState(0)
    const [touchStartX, setTouchStartX] = useState(0)

    function handleTouchStart(touchStartEvent) {
        touchStartEvent.preventDefault();

        const touch = touchStartEvent.touches[0];
        setTouchStartX(touch.pageX);

        console.dir(touchStartEvent);
    }
    function handleTouchMove(touchMoveEvent) {
        touchMoveEvent.preventDefault();

        const touch = touchMoveEvent.touches[0];
        let x = touch.pageX-touchStartX;
        if(x > 160) {x = 160}
        if(x < -160) {x = -160}

        if(props.data.actionOk === false) {
            if(x > 0) { x = 0}
        }

        setMarginLeft(x);

        //console.dir(touchMoveEvent);
    }
    function handleTouchEnd() {

        if(marginLeft < -100) {
            props.onRead(props.data.id, false)
        }
        if(marginLeft > 100) {
            //
            //alert("action:"+props.data.actionOkData);
            props.onRead(props.data.id, false);
            const apiUrl = 'https://api.promarena.ru/rest/?action=actionOk';
            fetch(apiUrl,
                {
                    method: 'POST',
                    headers: new Headers(
                        {"Content-Type": "application/json",
                            "Accept":"application/json"}
                    ),
                    body: JSON.stringify(
                        {'data': props.data.actionOkData}
                    )
                })
                .then((response) => response.json())
                .then((data) => {
                    console.dir(data);

                });
        }
        setMarginLeft(0);
    }

    function clickHandler() {
        props.onRead(props.data.id, props.data.link);
        window.location = props.data.link;
    }

    let styles = {
        transform: `translateX(${marginLeft}px)`,
    }

    return (
        <div className='notify-item-container'>
            <span className='label-ok'>Подтвердить</span>
            <span className='label-cancel'>Прочитано</span>
                <div onClick={clickHandler} className='notify-item'
                     style={styles}
                     onTouchStart={touchStartEvent => handleTouchStart(touchStartEvent)}
                     onTouchMove={touchMoveEvent => handleTouchMove(touchMoveEvent)}
                     onTouchEnd={() => handleTouchEnd()}
                >
                    <div className='notify-title'>{props.data.title}</div>
                    <div className='notify-detail-text'>{props.data.text}</div>

                </div>
        </div>
    )

}