import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Map, Placemark, YMaps} from "@pbe/react-yandex-maps";
import {useState} from "react";
import {Link} from "react-router-dom";
import { ReactComponent as BurgerSvg } from '../../images/burger.svg';
import { OrderNew } from '../../modules/OrderNew/index.js';
import { ReactComponent as CloseSvg } from '../../images/close-circled.svg';
import { ReactComponent as IconPersonal } from '../../images/icon_nav.svg';
import { ReactComponent as IconBalance } from '../../images/icon_balance.svg';
import { ReactComponent as IconHistory } from '../../images/icon_history.svg';
import { ReactComponent as IconSupport } from '../../images/icon_support.svg';
import { ReactComponent as IconSettings } from '../../images/icon_settings.svg';
import { ReactComponent as IconDocs } from '../../images/icon_docs.svg';
import { ReactComponent as IconAbout } from '../../images/icon_about.svg';
import { ReactComponent as IconExit } from '../../images/icon_exit.svg';

const defaultSettings = {
    enableHighAccuracy: false,
    timeout: Infinity,
    maximumAge: 0,
};

export const usePosition = (watch = false, userSettings = {}) => {
    const settings = {
        ...defaultSettings,
        ...userSettings,
    };

    const [position, setPosition] = useState({});
    const [error, setError] = useState(null);

    const onChange = ({coords, timestamp}) => {
        setPosition({
            latitude: coords.latitude,
            longitude: coords.longitude,
            accuracy: coords.accuracy,
            speed: coords.speed,
            heading: coords.heading,
            timestamp,
        });
    };

    const onError = (error) => {
        setError(error.message);
    };

    useEffect(() => {
        if (!navigator || !navigator.geolocation) {
            setError('Geolocation is not supported');
            return;
        }

        if (watch) {
            const watcher =
                navigator.geolocation.watchPosition(onChange, onError, settings);
            return () => navigator.geolocation.clearWatch(watcher);
        }

        navigator.geolocation.getCurrentPosition(onChange, onError, settings);
    }, [
        settings.enableHighAccuracy,
        settings.timeout,
        settings.maximumAge,
    ]);

    return {...position, error};
};

export function Home(props) {


    const [orderFormOpen, updateOrderFormState] = useState(false);

    const [marginSliderTop, setMarginSliderTop] = useState(0)
    const [touchStartY, setTouchStartY] = useState(0);

    const [isMenuOpen, setMenuOpen] = useState(false);

    const position = usePosition();

    function handleTouchStart(touchStartEvent) {

            touchStartEvent.preventDefault();
            const touch = touchStartEvent.touches[0];
            setTouchStartY(touch.pageY);

    }

    function handleTouchMove(touchMoveEvent) {

            touchMoveEvent.preventDefault();
            const touch = touchMoveEvent.touches[0];
            let y = touch.pageY- touchStartY;
            if(y < 0) {y=0;}
            setMarginSliderTop(y);

    }

    function handleTouchEnd() {
        if (marginSliderTop > 200) {
            closeOrder();
        }

        setMarginSliderTop(0);
    }


    function newOrder() {
        updateOrderFormState(true);
    }
    function closeOrder() {
        updateOrderFormState(false);
    }

    function logout() {
        console.log("logout");
        localStorage.removeItem('phone', false);
        localStorage.removeItem('isAuthorized', false);
        window.location.reload();
    }





    const sliderStyle = (orderFormOpen && (marginSliderTop > 0)) ? {
        transform: `translateY(${marginSliderTop}px)`,
        transition: `none`
    } : {};

    let defaultState;

    const positionFound = !!position?.latitude && !!position.longitude;
    if(positionFound) {
         defaultState = {
            center: [position?.latitude, position.longitude],
            zoom: 5,
            controls: []
        };
    } else {
        defaultState = {
            center: [55.751574, 37.573856],
            zoom: 5,
            controls: []
        };
    }

    console.log("position:");
    console.dir(position);
    return (
        <React.Fragment>
            <div className='page'>
                <div  className={isMenuOpen ? 'main-menu-container open' : 'main-menu-container'}>
                    <div className='main-menu'>
                        <div className='page-header'>

                        <a className='burgeg' onClick={()=>setMenuOpen(false)}>
                            <div className='nav-rounded-bg'>
                                <CloseSvg />
                            </div>
                        </a>
                            <div class='page-header-title'>
                                Меню
                            </div>
                        </div>
                        <div>

                        </div>
                        <div>
                            <div className='left-menu-user-info'>
                                <img src='/images/user_photo.png' className='userPic '/>
                                <div className='left-menu-user-info-desc'>
                                    <b>{props.user.name} {props.user.nameSecond}</b>
                                    <div>{props.user.phone}</div>
                                </div>

                            </div>
                            <div className='left-menu-items'>

                                <Link to="/personal/">
                                    <IconPersonal />
                                    <span>Личный кабинет</span>
                                </Link>
                                <Link to="/personal/balance/">
                                    <IconBalance />
                                    <span>Баланс</span>
                                </Link>
                                <Link to="/personal/history/">
                                    <IconHistory />
                                    <span>История заказов</span>
                                </Link>
                                <Link to="/support/">
                                    <IconSupport />
                                    <span>Поддержка</span>
                                </Link>
                                <Link to="/settings/">
                                    <IconSettings />
                                    <span>Настройки</span>
                                </Link>


                                <Link to="/about/">
                                    <IconAbout />
                                    О Приложении
                                </Link>
                                <a onClick={logout}>
                                    <IconExit />
                                    Выход
                                </a>
                            </div>

                        </div>
                    </div>
                    <div className='shadow' onClick={()=>setMenuOpen(false)}>

                    </div>
                </div>
                <YMaps >
                    <Map width="100vw"
                         height="100vh"
                         defaultState={defaultState}>
                        {positionFound &&
                        <Placemark geometry={[position.latitude, position.longitude]}/>
                        }
                    </Map>
                </YMaps>

                <div className='header action'>
                    <a className='burgeg' onClick={()=>setMenuOpen(true)}>
                        <div className='nav-rounded-bg'>
                            <BurgerSvg />
                        </div>
                    </a>
                    <div className='title'>Пром<span>Арена</span></div>
                    <div className='page-header-nav'></div>
                </div>
                <div className='content'>

                </div>
                <div className='footer action'>
                    <div className='col'>
                        <a className='btn btn-main' onClick={newOrder}>+ Cоздать заказ</a>
                    </div>
                    <div className='col'>
                        <Link className='btn btn-second' to="/orders/">Текущий заказ</Link>
                    </div>
                </div>
            </div>
            {orderFormOpen &&
                <div className="slider-shadow" onClick={closeOrder}></div>
            }
            <div className={orderFormOpen ? 'slider open' : 'slider'}
                 style={sliderStyle} >

                <div className='top'
                     onTouchStart={touchStartEvent => handleTouchStart(touchStartEvent)}
                     onTouchMove={touchMoveEvent => handleTouchMove(touchMoveEvent)}
                     onTouchEnd={() => handleTouchEnd()}


                    >
                    <div className='handle'></div>
                </div>

                <OrderNew user={props.user} onCloseOrder={closeOrder}/>

            </div>
        </React.Fragment>
    );

    /*
    <Link to="/docs/">
                                    <IconDocs />
                                    <span>Документы</span>
                                </Link>
     */

}