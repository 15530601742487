import {Link} from "react-router-dom";
import {ReactComponent as BackSvg} from "../../images/back.svg";
import React from "react";
import {ReactComponent as CalendarSvg} from "../../images/calendar.svg";
import {InputSelector} from "../../components/InputSelector";

export function AboutRekv(props) {
    return (
        <div className='page'>
            <div className='page-header'>
                <Link className='page-header-nav' to='/about/'>
                    <div className='nav-rounded-bg'>
                        <BackSvg />
                    </div>

                </Link>
                <div className='page-header-title'>
                   Контакты и реквизиты

                </div>
                <div className='page-header-nav'>

                </div>
            </div>
            <div>
                Индивидуальный предприниматель Яковлев Константин Иванович
                ОГРНИП 312422329900017
                ОКПО 0186766157
                ИНН 422307326715
                Юр.адрес:654244, РФ, Кемеровская область, Новокузнецкий район,
                пос.Загорский, ул.Чубейко, 20.
                Банковские реквизиты:
                Расчетный счет в валюте РФ:
                № 40802.810.126.000.018.163
                в  Кемеровское отделение 8615
                ПАО «Сбербанк»
                БИК 043 207 612
                Корр. счет:  30101.810.200.000.000.612
                в  СИБИРСКОЕ ГУ БАНКА РОССИИ
                Сот.тел. 89069317998
                E:mail:  kiya80@mail.ru
            </div>
        </div>
    )
}