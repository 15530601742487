import {Link} from "react-router-dom";
import {ReactComponent as BackSvg} from "../../images/back.svg";
import React, {useState} from "react";
import {useParams} from "react-router";
import {InputField} from "../../components/InputSelector";

export function TechListItem(props) {

    let { itemId } = useParams();
    const [tech, updateTech] = useState(false);
    const [isReady, updateReadyState] = useState(false);
    const [sendState,updateSendState] = useState(false);

    if(!isReady) {
        const apiUrl = 'https://api.promarena.ru/rest/?action=getTechItem&id='+itemId;
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                console.log('This is your data', data);
                if (data.success) {
                    console.log("success");
                    console.dir(data.data.list);
                    updateReadyState(true);
                    updateTech(data.data);
                }
            });
    }

    const [typeList,updateTypeList] = useState(false);

    if(typeList === false) {
        const apiUrl = 'https://api.promarena.ru/rest/?action=getTypeList';
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                console.log('This is your type list data', data);
                updateTypeList(data.list);
            });
    }

    function saveTech() {
        updateSendState(true);
        const telegramId = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id;
        const apiUrl = 'https://api.promarena.ru/rest/?action=saveTech';
        fetch(apiUrl,
            {
                method: 'POST',
                headers: new Headers(
                    {"Content-Type": "application/json",
                        "Accept":"application/json"}
                ),
                body: JSON.stringify(
                    {'tech': tech}
                )
            }
        )
        .then((response) => {
            updateSendState(false);
            return response.json();
        })
        .then((data) => {
            console.log('This is your data', data);
            console.dir(data);
            window.location.href = "/executor/list/";
        });
    }

    return (
        <div className='page telegram'>
            <div className='page-header'>
                <Link className='page-header-nav' to={"/executor/list/"+itemId+"/"}>
                    <div className='nav-rounded-bg'>
                        <BackSvg />
                    </div>

                </Link>
                <div className='page-header-title'>
                    Техника
                </div>
                <div className='page-header-nav'>

                </div>
            </div>

            <div>
                {isReady ? (
                     <div>
                         <div className='input-field-container'>
                             Фотографии:
                             <div className="tech-photo-list-container">
                                 <div className="tech-photo-add">
                                     Добавить фото
                                 </div>
                                 <div className="tech-photo-list">
                                     {tech.photos.map(photo =>
                                        <div className="tech-photo-item">
                                            <div className="tech-photo-remove">X</div>
                                            <img src={"https://api.promarena.ru"+photo} />
                                        </div>
                                     )}
                                 </div>
                             </div>
                         </div>
                         <div className='input-field-container'>
                             <label>Тип техники</label>
                             {typeList !== false &&
                             <div className='input-field'>
                                 <select
                                     value={tech.type}
                                     onChange={(event)=>{updateTech(prevState => {
                                         let techState = {...prevState};
                                         techState.type = event.target.value;
                                         return techState;
                                     })}}
                                 >
                                     {typeList.map(item => (
                                         <option value={item.ID}>{item.UF_NAME}</option>
                                     ))}
                                 </select>
                             </div>
                             }
                         </div>
                         <div className='input-field-container'>
                             <label className="mt-10">Марка и модель</label>
                             <InputField title='Марка и модель' type='text'
                                         value={tech.model}
                                         onChange={(event)=>{updateTech(prevState => {
                                             let techState = {...prevState};
                                             techState.model = event.target.value;
                                             return techState;
                                         })}}
                             />
                         </div>
                         <div className='input-field-container '>
                             <label className="mt-10">Регистрационный номер</label>
                             <InputField title='Регистрационный номер' type='text'
                                         value={tech.reg_num}
                                         onChange={(event)=>{updateTech(prevState => {
                                             let techState = {...prevState};
                                             techState.reg_num = event.target.value;
                                             return techState;
                                         })}}
                             />
                         </div>
                         <div className='input-field-container '>
                             <label className="mt-10">Стоимость</label>
                             <InputField title='Стоимость' type='number'
                                         value={tech.price}
                                         onChange={(event)=>{updateTech(prevState => {
                                             let techState = {...prevState};
                                             techState.price = event.target.value;
                                             return techState;
                                         })}}
                             />
                         </div>
                         <div className='input-field-container '>
                             <div className='col w100 mt-10'>
                                 {sendState ? (
                                     <a className='btn btn-main' >Отправкка запроса...</a>
                                 ) : (
                                     <a className='btn btn-main' onClick={saveTech}>Сохранить</a>
                                 )}
                             </div>
                         </div>
                     </div>
                    ) : (
                     <div>загрузка...</div>
                )

                }
            </div>
        </div>
    )
}