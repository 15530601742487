import {Link} from "react-router-dom";
import {ReactComponent as BackSvg} from "../../images/back.svg";
import React, {useState} from "react";
import {useParams} from "react-router";
import {InputField} from "../../components/InputSelector";
import {Uploader} from "../../components/Uploader";
function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}
export function TechListAdd(props) {

    const telegramId = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id;
    const [orderTempId, updateOrderTempId] = useState(getRandomInt(1000).toString()+":"+Date.now().toString());

    let { tgId } = useParams();
    const [sendState,updateSendState] = useState(false);
    const [tech, updateTech] = useState(
        {
            'type' : false,
            'model':'',
            'reg_num' : '',
            'price' : 1000
        }
    );

    const [typeList,updateTypeList] = useState(false);

    if(typeList === false) {
        const apiUrl = 'https://api.promarena.ru/rest/?action=getTypeList';
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                console.log('This is your type list data', data);
                updateTypeList(data.list);

                updateTech(prevState => {
                    let techState = {...prevState};
                    techState.type = data.list[0].ID;
                    return techState;
                })
            });
    }



    function addTech() {
        updateSendState(true);
        const telegramId = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id;
        const apiUrl = 'https://api.promarena.ru/rest/?action=addTech';
        fetch(apiUrl,
            {
                method: 'POST',
                headers: new Headers(
                    {"Content-Type": "application/json",
                        "Accept":"application/json"}
                ),
                body: JSON.stringify(
                    {'tech': tech,'telegramId': telegramId,'tempId':orderTempId}
                )
            }
        )
        .then((response) => {
            updateSendState(false);
            return response.json();
        })
        .then((data) => {
            console.log('This is your data', data);
            console.dir(data);
            window.location.href = "/executor/list/";
        });

    }


    return (
        <div className='page telegram'>
            <div className='page-header'>
                <Link className='page-header-nav' to={"/executor/list/"+tgId+"/"}>
                    <div className='nav-rounded-bg'>
                        <BackSvg />
                    </div>

                </Link>
                <div className='page-header-title'>
                    Техника
                </div>
                <div className='page-header-nav'>

                </div>
            </div>

            <div  className='content-wrapper'>
                <Uploader orderTempId={orderTempId} />

                <div className='input-field-container'>
                    <label>Тип техники</label>
                    {typeList !== false &&
                    <div className='input-field'>
                        <select
                            onChange={(event)=>{updateTech(prevState => {
                                let techState = {...prevState};
                                techState.type = event.target.value;
                                return techState;
                            })}}
                        >
                            {typeList.map(item => (
                                <option value={item.ID}>{item.UF_NAME}</option>
                            ))}
                        </select>
                    </div>
                    }
                </div>


                <div className='input-field-container'>
                    <label>Марка и модель</label>
                    <InputField title='Марка и модель' type='text'
                                value={tech.model}
                                onChange={(event)=>{updateTech(prevState => {
                                    let techState = {...prevState};
                                    techState.model = event.target.value;
                                    return techState;
                                })}}
                    />
                </div>
                <div className='input-field-container'>
                    <label>Регистрационный номер</label>
                    <InputField title='Регистрационный номер' type='text'
                                value={tech.reg_num}
                                onChange={(event)=>{updateTech(prevState => {
                                    let techState = {...prevState};
                                    techState.reg_num = event.target.value;
                                    return techState;
                                })}}
                    />
                </div>
                <div className='input-field-container'>
                    <label>Стоимость</label>
                    <InputField title='Стоимость' type='number'
                                value={tech.price}
                                onChange={(event)=>{updateTech(prevState => {
                                    let techState = {...prevState};
                                    techState.price = event.target.value;
                                    return techState;
                                })}}
                    />
                </div>
                <div className='col'>
                    {sendState ? (
                        <a className='btn btn-main' >Отправкка запроса...</a>
                    ) : (
                        <a className='btn btn-main' onClick={addTech}>Добавить</a>
                    )}

                </div>
            </div>
        </div>
    )
}