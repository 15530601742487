import React, {useState} from "react";
import {ReactComponent as StarSvg} from "../../images/star.svg";
import {ReactComponent as StarActiveSvg} from "../../images/star-active.svg";
import {ReactComponent as SupportSvg} from "../../images/support.svg";
import {ReactComponent as DisputSvg} from "../../images/disput.svg";
import {Link} from "react-router-dom";
export function OrderReview(props) {

    const [reviewText, updateReviewText] = useState('');
    const [reviewRate, updateReviewRate] = useState(0);
    const [inActionState, updateInActionState] = useState(false);

    let stars = [];
    for(let i = 0; i < 5; i++) {
        stars.push(i);
    }

    function sendReview() {
        console.log("sendReview");
        const apiUrl = 'https://api.promarena.ru/rest/?action=addOrderReview';
        updateInActionState(true);
        fetch(apiUrl,
            {
                method: 'POST',
                headers: new Headers(
                    {"Content-Type": "application/json",
                        "Accept":"application/json"}
                ),
                body: JSON.stringify(
                    {'review':
                                {
                                    'orderId' : props.orderId,
                                    'phone' : props.executorPhone,
                                    'rate' : reviewRate,
                                    'text' : reviewText
                                }
                         }
                )
            }
        )
            .then((response) => response.json())
            .then((data) => {
                console.log("Review succefully send");
                console.log('This is your data', data);
                updateInActionState(false);
                props.onReviewSend();

            });
        //
    }

    return (
        <React.Fragment>
            <div className='content-wrapper'>

                {((props.executorReview === false) || (props.orderStatus != 'DONE')) &&
                    <div>
                        <div className='item-title'>Отзыв</div>
                        <textarea className='input-details' value={reviewText} onChange={(e)=>updateReviewText(e.target.value)}>{reviewText}</textarea>

                        <div className='review-rate'>
                            <div className={reviewRate > 0 ? 'item active' : 'item'} onClick={()=>updateReviewRate(1)}>
                              {reviewRate > 0 ? ( <StarActiveSvg /> ) : (<StarSvg />) }
                            </div>
                            <div className={reviewRate > 1 ? 'item active' : 'item'} onClick={()=>updateReviewRate(2)}>
                                {reviewRate > 1 ? ( <StarActiveSvg /> ) : (<StarSvg />) }
                            </div>
                            <div className={reviewRate > 2 ? 'item active' : 'item'} onClick={()=>updateReviewRate(3)}>
                                {reviewRate > 2 ? ( <StarActiveSvg /> ) : (<StarSvg />) }
                            </div>
                            <div className={reviewRate > 3 ? 'item active' : 'item'} onClick={()=>updateReviewRate(4)}>
                                {reviewRate > 3 ? ( <StarActiveSvg /> ) : (<StarSvg />) }
                            </div>
                            <div className={reviewRate > 4 ? 'item active' : 'item'} onClick={()=>updateReviewRate(5)}>
                                {reviewRate > 4 ? ( <StarActiveSvg /> ) : (<StarSvg />) }
                            </div>
                        </div>

                        <div className='sep'></div>
                    </div>

                }





                <p>Подходите с отвественностью к написанию отзывов, так как Ваша учетная запись может быть заморожена за ложную информацию.</p>
                <div className='sep'></div>

                <Link className='btn-icon-link' to={"/support/"+props.orderId+"/"}>
                    <SupportSvg /><span>Написать в поддержку</span>
                </Link>
                <div className='sep'></div>
                <Link className='btn-icon-link' to={"/support/"+props.orderId+"/"}>
                    <DisputSvg /><span>Открыть разбирательство</span>
                </Link>
                <div className='sep'></div>
                {((reviewRate > 0) && (props.executorReview === false)) &&

                    <div>
                        <p><b>Ваша оценка</b></p>
                        <p>{reviewRate}.0</p>
                    </div>
                }
                {(props.executorReview !== false) &&
                <div>
                    <p><b>Ваша оценка</b></p>
                    <div className='btn-icon-link'>
                        <span>{props.executorReview.UF_RATE}.0</span>
                        <div className='review-rate'>
                            {stars.map((item)=>
                                <React.Fragment>
                                    {(item < props.executorReview.UF_RATE) ? (
                                        <StarActiveSvg />
                                    ) : (
                                        <StarSvg />
                                    )}

                                </React.Fragment>

                            )}
                        </div>
                    </div>
                    <p><b>Ваш комментарий к оценке</b></p>
                    <p>{props.executorReview.UF_TEXT}</p>
                </div>
                }
                {((props.executorReview === false) || (props.orderStatus != 'DONE')) &&
                    <div>
                        {((reviewRate) > 0 && (inActionState == false)) ? (
                            <div className="btn flex-none btn-main" onClick={sendReview}>Подтвердить</div>
                        ) :(
                            <div className="btn flex-none" >Оставьте отзыв и оценку</div>
                        )}
                    </div>
                }
            </div>

        </React.Fragment>
    )
}