import React, {useState} from "react";
import {InputSelector} from "../../components/InputSelector";

import { ReactComponent as CalendarSvg } from '../../images/calendar.svg';
import { ReactComponent as ClockSvg } from '../../images/clock.svg';
import { ReactComponent as MarkerSvg } from '../../images/marker.svg';
import {Link} from "react-router-dom";
import {ReactComponent as BackSvg} from "../../images/back.svg";
import {useParams} from "react-router";


export function Support(props) {

    let { orderId } = useParams();

    const [messageText, updateMessageText] = useState('');
    const [messageList, updateMessageList] = useState([]);
    const [isReady, updateReadyState] = useState(false);

    const apiUrl = 'https://api.promarena.ru/rest/?action=getSupportChat&orderId='+orderId;

    console.log("PROPS");
    console.dir(props.user);

    if(!isReady) {
        fetch(apiUrl,{
                method: 'POST',
                    headers: new Headers(
                    {"Content-Type": "application/json",
                        "Accept":"application/json"}
                ),
                    body: JSON.stringify(
                    {
                        'userPhone' : props.user.phone,
                        'orderId':orderId
                    }
                )
            })
            .then((response) => response.json())
            .then((data) => {
                console.log('This is your data', data);
                if (data.success) {
                    console.log("success");
                    console.dir(data.result);
                    updateMessageList(data.result);
                    updateReadyState(true);
                }
            });
    }



    function sendMessage() {
        if(messageText.length > 0) {

            const apiSendUrl = 'https://api.promarena.ru/rest/?action=sendSupportMessage';

            fetch(apiSendUrl,
                {
                    method: 'POST',
                    headers: new Headers(
                        {"Content-Type": "application/json",
                            "Accept":"application/json"}
                    ),
                    body: JSON.stringify(
                        {   'userPhone' : props.user.phone,
                                  'text':messageText,
                                  'orderId':orderId
                        }
                    )
                }
            ).then((response) => response.json())
                .then((data) => {
                    console.log('This is your data', data);
                    updateReadyState(false);
                });

            updateMessageList(prevState => {
                    let newMessageList = [...prevState];
                    newMessageList.push(
                    {
                        'fromUser': true,
                        'text': messageText,
                        'time': '20:25',
                    });
                    return newMessageList;
                });
            updateMessageText('');
        }
    }

    return (
        <div className='page'>
            <div className='page-header'>
                <Link className='page-header-nav' to={orderId ? '/orders/' : '/'} >
                    <div className='nav-rounded-bg'>
                        <BackSvg />
                    </div>

                </Link>
                <div className='page-header-title'>
                    <span>Поддержка</span>
                    {orderId &&
                        <span>&nbsp;по заказу #{orderId}</span>
                    }

                </div>
                <div className='page-header-nav'>

                </div>
            </div>
            <div className='message-list'>

                {messageList.map(item =>
                    <div className={(item.fromUser==1) ? 'message-item-container from' : 'message-item-container to' }>
                        {(item.fromUser==0) &&
                            <div className='label'>Оператор</div>
                        }
                        <div className='message-item'>
                            <span className='text'>{item.text}</span>
                            <span className='time'>{item.time}</span>
                        </div>
                    </div>
                )}
            </div>
            <div className='action'>
                <input className='message-input-text'
                       placeholder='Опишите проблему'
                       value={messageText}
                       onChange={e => updateMessageText(e.target.value)}
                />

                <a href onClick={sendMessage} className={(messageText.length > 0) ? 'btn btn-main btn-msg-send' : 'btn btn-msg-send'}>Написать</a>
            </div>
        </div>
    )
}