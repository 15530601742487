import React, {useState} from "react";
import {InputSelector} from "../../components/InputSelector";

import { ReactComponent as CalendarSvg } from '../../images/calendar.svg';
import { ReactComponent as ClockSvg } from '../../images/clock.svg';
import { ReactComponent as MarkerSvg } from '../../images/marker.svg';
import {Uploader} from "../../components/Uploader";
import {Link} from "react-router-dom";
import {ReactComponent as BackSvg} from "../../images/back.svg";
import {ReactComponent as OrderOpenSvg} from "../../images/order-open.svg";
import {ReactComponent as WalletSvg} from "../../images/wallet.svg";
import {ReactComponent as EditSvg} from "../../images/edit.svg";
function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export function OrderNew(props) {
    const [isSelectAddr, setSelectAddrState] = useState(false);
    const [isReadyOrderList, updateReadyOrderListState] = useState(false);
    const apiUrlOrderList = 'https://api.promarena.ru/rest/?action=getOrderAddrList&phone='+props.user.phone;
    const [orderList, updateOrderList] = useState({});

    if(!isReadyOrderList) {
        fetch(apiUrlOrderList)
            .then((response) => response.json())
            .then((data) => {
                console.log('This is your order list data', data);
                if (data.success) {
                    console.log("success order list");
                    updateReadyOrderListState(true);
                    updateOrderList(data.list);
                }
            });
    }

    const [orderDetailTab,setOrderDetailTab] = useState(false);
    const [orderTempId, updateOrderTempId] = useState(getRandomInt(1000).toString()+":"+Date.now().toString());
    const [inSendState,updateSendState] = useState(false);
    const [order, updateOrderState] = useState(
{
            'type':'',
            'subtype' : false,
            'detail':'',
            'date':'',
            'time':'',
            'addr':'',
            'city':'',
            'phone': props.user.phone,
            'hours' : 1,
            'param1' : false,
            'param2' : false,
            'param3' : false
         }
    );

    const [subTypes,setSubTypes] = useState([]);

    const [typeList,updateTypeList] = useState(false);

    const [param1,updateParam1] = useState(false);
    const [param2,updateParam2] = useState(false);
    const [param3,updateParam3] = useState(false);

    const [avgPrice,setAvgPrice] = useState(0);
    const [suggestAddrList, setSuggestAddrList] = useState([]);
    if(typeList === false) {
        const apiUrl = 'https://api.promarena.ru/rest/?action=getTypeList';
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                console.log('This is your type list data', data);
                updateTypeList(data.list);
            });
    }
    function resetOrder() {
        console.log("RESET");
        updateOrderState({
            'type':'',
            'subtype' : false,
            'detail':'',
            'date':'',
            'time':'',
            'addr':'',
            'city':'',
            'phone': props.user.phone,
            'hours' : 1,
            'param1' : false,
            'param2' : false,
            'param3' : false
        });
        props.onCloseOrder();
    }

    function sendOrder(phone,detail) {
        if(order.type == '') {
            alert("Не выбран тип техники");
            return;
        }
        if(order.date == '') {
            alert("Не указана дата");
            return;
        }
        if(order.time == '') {
            alert("Не указано время");
            return;
        }
        if(order.addr == '') {
            alert("Не указан адрес");
            return;
        }

        updateSendState(true);
        const apiUrl = 'https://api.promarena.ru/rest/?action=addOrder';
        fetch(apiUrl,
            {
                    method: 'POST',
                    headers: new Headers(
                    {"Content-Type": "application/json",
                        "Accept":"application/json"}
                    ),
                    body: JSON.stringify(
                    {'order': order,'temp_id':orderTempId}
                    )
                }
            )
            .then((response) => {
                updateSendState(false);
                return response.json();
            })
            .then((data) => {
                console.log('This is your data', data);
                if(data.success) {
                    updateOrderTempId(getRandomInt(1000).toString()+":"+Date.now().toString());

                    props.onCloseOrder();
                }
            });
    }


    function onChangeOrderDetail(event) {
        updateOrderState((prevState) => ({
            ...prevState,
            detail: event.target.value,
        }))
    }

    console.dir(order);


    if(isSelectAddr) {
        return (
            <React.Fragment>
                <div className='header'>
                    <div className='close-order new-order-reset' onClick={()=>setSelectAddrState(false)}>Назад</div>
                </div>
                <div className='content'>
                    <textarea className='input-addr' placeholder='Введите адрес'
                           value={order.addr}
                           onChange={(event) => {
                               console.log("addr:"+event.target.value);
                               const apiUrl = 'https://api.promarena.ru/rest/?action=suggestAddress';
                               fetch(apiUrl,
                                   {
                                       method: 'POST',
                                       headers: new Headers(
                                           {"Content-Type": "application/json",
                                               "Accept":"application/json"}
                                       ),
                                       body: JSON.stringify(
                                           {'q':event.target.value}
                                       )
                                   }
                               )
                               .then((response) => response.json())
                               .then((data) => {
                                   console.log("address");
                                   console.dir(data)
                                   setSuggestAddrList(data);
                               });


                               updateOrderState((prevState) => ({
                                   ...prevState,
                                   addr: event.target.value,
                               }))}

                           }
                    />

                    {isReadyOrderList &&
                        <div className='addr-history-list'>
                        {suggestAddrList.map(item => (
                            <div className='addr-history-item' onClick={() => updateOrderState((prevState) => ({
                                ...prevState,
                                addr: item.value,
                                city: item.data.city ? item.data.city : item.data.area
                            }))}>
                                {item.value}
                            </div>
                        ))}
                        {orderList.map(item => (
                            <div class='addr-history-item' onClick={()=>updateOrderState((prevState) => ({
                                ...prevState,
                                addr: item.text,
                                city: item.city
                            }))}>
                                {item.text}

                                {(item.city !== "") &&
                                    <span>({item.city})</span>
                                }
                            </div>
                        ))}
                        </div>
                    }
                </div>
                <div className='action'>
                    <a className='btn btn-main' onClick={()=>setSelectAddrState(false)}>Указать адрес</a>
                </div>
            </React.Fragment>
        )
    } else {

        return (
            <React.Fragment>

                {(orderDetailTab === false) ? (

                    <React.Fragment>
                        <div className='header'>
                            <div className='new-order-details-link' onClick={() => setOrderDetailTab("params")}>Детали
                                заказа
                            </div>
                            <div className='close-order new-order-reset' onClick={resetOrder}>Сбросить</div>
                        </div>
                        <div className='content'>
                            <div className='row'>
                                <div className='col new-order-btn-container'>
                                    <div className='new-order-btn' onClick={() => {
                                        document.getElementById("new-order-date").focus();

                                    }}>
                                        <CalendarSvg/>
                                        <div className="text">
                                            <span>Дата</span>
                                            <div>
                                                <input type="date" id="new-order-date"
                                                       value={order.date}
                                                       onFocus={(e) => {

                                                           e.target.showPicker()
                                                       }}
                                                       onChange={(event) => updateOrderState((prevState) => ({
                                                           ...prevState,
                                                           date: event.target.value,
                                                       }))}
                                                />
                                            </div>
                                        </div>
                                        <EditSvg/>
                                    </div>

                                    <div className='new-order-btn' onClick={() => {
                                        document.getElementById("new-order-time").focus();

                                    }}>
                                        <ClockSvg/>
                                        <div className="text">
                                            <span>Время</span>
                                            <div>
                                                <input type="time" id="new-order-time"
                                                       value={order.time}
                                                       onFocus={(e) => {
                                                           e.target.showPicker()
                                                       }}
                                                       onChange={(event) => updateOrderState((prevState) => ({
                                                           ...prevState,
                                                           time: event.target.value,
                                                       }))}
                                                />
                                            </div>
                                        </div>
                                        <EditSvg/>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col new-order-btn-container'>
                                    <div className='new-order-btn' onClick={()=>setSelectAddrState(true)}>
                                        <MarkerSvg/>
                                        <div className="text">
                                            <span>Адрес</span>
                                            <div>
                                                {order.addr ? order.addr : 'Указать адрес'}
                                                {order.city ? " ("+order.city+")" : ''}
                                            </div>
                                        </div>
                                        <div className='btn-main btn'>Выбрать</div>
                                    </div>
                                </div>
                            </div>


                            {typeList !== false &&
                            <React.Fragment>
                                <div className='sliderTechType'>
                                    {typeList.map(item => (
                                        <div key={item.ID} className={(item.ID == order.type) ? 'item active' : 'item'}
                                             onClick={(event) => {
                                                 updateOrderState((prevState) => ({
                                                     ...prevState,
                                                     type: item.ID,
                                                     subtype: false,
                                                     param1: false,
                                                     param2: false,
                                                     param3: false
                                                 }))
                                                 console.log("SELECT!");
                                                 console.dir(item.UF_SUB_TYPES)
                                                 setSubTypes(item.SUB_TYPES);
                                                 updateParam1(item.param1);
                                                 updateParam2(item.param2);
                                                 updateParam3(item.param3);

                                                 setAvgPrice(item.AVG_PRICE);
                                             }}
                                        >

                                            <img src={item.UF_ICON.toString()}/>
                                            <span>{item.UF_NAME}</span>
                                            {(parseInt(item.AVG_PRICE) > 0) &&
                                            <div className="tech-avg-price">~{item.AVG_PRICE}&nbsp;₽/час</div>
                                            }
                                        </div>
                                    ))}
                                </div>



                                <div className="row">
                                    <div className="col-2">
                                        <div className='order-field'>
                                            <div className='icon success'>
                                                <WalletSvg/>
                                            </div>
                                            <div className='text-left'>
                                                <div><b>Итоговая стоимость</b></div>
                                                {(parseInt(avgPrice) > 0) ? (
                                                        <span>~{avgPrice * order.hours}₽</span>
                                                    )
                                                    : (
                                                        <span>после оформления заказа</span>
                                                    )
                                                }
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className='order-field payment-method'>

                                            <div className='text-left'>
                                                <div><b>Способ оплаты</b></div>

                                                        <span>Налчиные</span>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                            }
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>

                        <div className='page-header'>
                            <div className="page-header-nav" onClick={() => setOrderDetailTab(false)}>
                                <div className="nav-rounded-bg">
                                    <svg width="11" height="20" viewBox="0 0 11 20" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M0.366239 9.11624C-0.12191 9.6044 -0.12191 10.3959 0.366239 10.884L8.32119 18.839C8.80935 19.3271 9.6008 19.3271 10.089 18.839C10.5771 18.3508 10.5771 17.5594 10.089 17.0712L3.01789 10.0001L10.089 2.929C10.5771 2.44088 10.5771 1.6495 10.089 1.16125C9.6008 0.673126 8.80935 0.673126 8.32119 1.16125L0.366239 9.11624ZM2.50013 8.75013L1.25013 8.75013L1.25013 11.2501L2.50013 11.2501L2.50013 8.75013Z"
                                            fill="#2D7CF2"></path>
                                    </svg>
                                </div>
                            </div>
                            <div className='page-header-title'>
                                Детали заказа


                            </div>
                            <div className='order-detail-reset page-header-nav' onClick={props.onCloseOrder}>
                                Сбросить
                            </div>
                        </div>


                        <div className='content order-detail-tabs'>
                            <div className="tabs row">
                                <div onClick={() => setOrderDetailTab("params")}
                                     className={(orderDetailTab === "params") ? "tab active" : "tab"}>Параметры
                                </div>
                                <div onClick={() => setOrderDetailTab("worktime")}
                                     className={(orderDetailTab === "worktime") ? "tab active" : "tab"}>Срок работы
                                </div>
                                <div onClick={() => setOrderDetailTab("photos")}
                                     className={(orderDetailTab === "photos") ? "tab active" : "tab"}>Фото/Видео
                                </div>
                            </div>
                            {(orderDetailTab === 'params') &&
                            <div className="w100">
                                {order.type === '' &&
                                    <div class="row">
                                        <div class="col align-items-center">
                                            <p>Техника не выбрана</p>
                                        </div>
                                    </div>
                                }

                                {((subTypes !== false) && (subTypes.length > 1)) &&
                                <div className="param-values">
                                    {subTypes.map(subType => (
                                        <div className={(subType == order.subtype) ? "btn active" : "btn"}
                                             onClick={(event) => updateOrderState((prevState) => ({
                                                 ...prevState,
                                                 subtype: subType,

                                             }))}>{subType}</div>
                                    ))}
                                </div>
                                }

                                <div>
                                    {(param1 !== false) &&
                                    <div>
                                        {param1.title}({param1.measure})
                                        <div className="param-values">
                                            {param1.values.map(value => (
                                                <div className={(value == order.param1) ? "btn active" : "btn"}
                                                     onClick={(event) => updateOrderState((prevState) => ({
                                                         ...prevState,
                                                         param1: value,
                                                     }))}>{value}</div>
                                            ))}
                                        </div>
                                    </div>
                                    }
                                </div>
                                <div>
                                    {(param2 !== false) &&
                                    <div>
                                        {param2.title}({param1.measure})
                                        <div className="param-values">
                                            {param2.values.map(value => (
                                                <div className={(value == order.param2) ? "btn active" : "btn"}
                                                     onClick={(event) => updateOrderState((prevState) => ({
                                                         ...prevState,
                                                         param2: value,
                                                     }))}>{value}</div>
                                            ))}
                                        </div>
                                    </div>
                                    }
                                </div>
                                <div>
                                    {(param3 !== false) &&
                                    <div>
                                        {param3.title}({param1.measure})
                                        <div className="param-values">
                                            {param3.values.map(value => (
                                                <div className={(value == order.param3) ? "btn active" : "btn"}
                                                     onClick={(event) => updateOrderState((prevState) => ({
                                                         ...prevState,
                                                         param3: value,
                                                     }))}>{value}</div>
                                            ))}
                                        </div>
                                    </div>
                                    }
                                </div>

                            </div>
                            }
                            {(orderDetailTab === 'worktime') &&
                            <div className='row'>
                                <div className='col'>
                                    <p>Укажите необходимое число часов</p>
                                    <div className='order-hours-selector'>
                                        <div className="btn" onClick={() => updateOrderState(prevState => {
                                            let orderState = {...prevState};
                                            orderState.hours = orderState.hours - 1;
                                            if (orderState.hours < 1) {
                                                orderState.hours = 1;
                                            }
                                            return orderState;
                                        })}>-
                                        </div>
                                        <div className="value">{order.hours}</div>
                                        <div className="btn" onClick={() => updateOrderState(prevState => {
                                            let orderState = {...prevState};
                                            if(orderState.hours < 100) {
                                                orderState.hours = orderState.hours + 1;
                                            }
                                            return orderState;
                                        })}>+
                                        </div>
                                    </div>
                                    <p>Продолжительность смены 7 часов</p>
                                </div>
                            </div>
                            }
                            <div className={(orderDetailTab === 'photos') ? "row" : "d-none"}>
                                <div className="col w100 text-left">
                                <div>Параметры</div>
                                <label>Опишите, что Вам требуется:</label>
                                <textarea maxLength={100} className='input-details' value={order.detail}
                                          onChange={onChangeOrderDetail}>{order.detail}</textarea>
                                <Uploader orderTempId={orderTempId}/>
                                </div>
                            </div>

                        </div>
                    </React.Fragment>
                )}


                <div className='action'>

                    {inSendState ? (
                        <a className='btn btn-main'>Отправка заказа...</a>
                    ) : (
                        <a className='btn btn-main'
                           onClick={() => sendOrder(props.user.phone, order.detail)}>Заказать</a>
                    )}

                </div>
            </React.Fragment>
        )
    }
}

/*

 <div className='row'>
                               <div className='col new-order-btn-container'>
                                   <InputSelector title='Дата аренды' type='date' icon={<CalendarSvg />}
                                                  onChange={(event)=>updateOrderState((prevState) => ({
                                                      ...prevState,
                                                      date: event.target.value,
                                                  }))}
                                   />
                               </div>
                               <div className='col  mw-half-8px'>
                                   <div>Время подачи</div>
                                   <InputSelector title='Время подачи' type='time' icon={<ClockSvg />}
                                                  onChange={(event)=>updateOrderState((prevState) => ({
                                                      ...prevState,
                                                      time: event.target.value,
                                                  }))}
                                   />
                               </div>
                           </div>

 */