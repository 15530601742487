import {Link} from "react-router-dom";
import {ReactComponent as BackSvg} from "../../images/back.svg";
import React from "react";
import {useParams} from "react-router";

export function OfferItem(props) {

    let { offerId, orderId } = useParams();


    function acceptOffer() {

        console.log("accept"+offerId);
        const apiUrl = 'https://api.promarena.ru/rest/?action=acceptOffer&offerId='+offerId;

        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                console.log('This is your data', data);
                if(data.success) {
                    console.log("success");
                    window.location.replace("/order/"+orderId+"/");

                }
        });
    }

    function rejectOffer() {
        console.log("reject"+offerId);
        const apiUrl = 'https://api.promarena.ru/rest/?action=rejectOffer&offerId='+offerId;
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                console.log('This is your data', data);
                if(data.success) {

                    console.log("success");
                    window.location.replace("/offers/"+orderId);
                }
            });
    }

    return (
        <React.Fragment>
            <div className='page-header'>
                <Link className='page-header-nav' to={"/offers/"+orderId}>
                    <div className='nav-rounded-bg'>
                        <BackSvg />
                    </div>

                </Link>
                <div className='page-header-title'>
                    Предложение #{offerId}
                </div>
                <div className='page-header-nav'>

                </div>
            </div>
            <div className='content-wrapper'>
                <a className='btn btn-main' onClick={acceptOffer}>Выбрать исполнителя</a>
                <a className='btn' onClick={rejectOffer}>Отклонить предложение</a>
            </div>
        </React.Fragment>
    )
}