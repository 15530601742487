import React, {useState} from "react";
import {Link} from "react-router-dom";
import { ReactComponent as BackSvg } from '../../images/back.svg';
import { ReactComponent as CancelConfirmedSvg } from '../../images/cancel-confirmed.svg';
import { ReactComponent as OrderOpenSvg } from '../../images/order-open.svg';
import { ReactComponent as OrderClosedSvg } from '../../images/order-closed.svg';
import { ReactComponent as OrderAddrSvg } from '../../images/order-marker.svg';
import { ReactComponent as OrderDateSvg } from '../../images/order-date.svg';
function OrderItem(props) {
    let orderItem = props.item;

    const [marginLeft, setMarginLeft] = useState(0)
    const [touchStartX, setTouchStartX] = useState(0)

    function handleTouchStart(touchStartEvent) {
        touchStartEvent.preventDefault();
        if(orderItem.UF_STATUS=='CANCEL') return;
        const touch = touchStartEvent.touches[0];
        setTouchStartX(touch.pageX);

        console.dir(touchStartEvent);
    }
    function handleTouchMove(touchMoveEvent) {
        touchMoveEvent.preventDefault();
        if(orderItem.UF_STATUS=='CANCEL') return;
        const touch = touchMoveEvent.touches[0];
        let x = touch.pageX-touchStartX;
        if(x > 0) {x = 0}
        if(x < -160) {x = -160
        }
        setMarginLeft(x);

        //console.dir(touchMoveEvent);
    }
    function handleTouchEnd() {

        if(orderItem.UF_STATUS=='CANCEL') return;
        if(marginLeft < -120) {props.onCanelOrder()};
        setMarginLeft(0);
    }

    let styles = {
        transform: `translateX(${marginLeft}px)`,
    }

    let linkTo = orderItem.UF_SELECTED_OFFER_ID ? '/order/'+orderItem.ID+"/" : '/offers/'+orderItem.ID+"/";

    return (
        <div className='order-item-container'>
            <span className='label-cancel'>Отменить</span>
            <div className='order-item'
                 style={styles}
                 onTouchStart={touchStartEvent => handleTouchStart(touchStartEvent)}
                 onTouchMove={touchMoveEvent => handleTouchMove(touchMoveEvent)}
                 onTouchEnd={() => handleTouchEnd()}
                >
                <Link  to={linkTo}>
                    {orderItem.UF_SELECTED_OFFER_ID ? (
                        <React.Fragment>
                            {(orderItem?.status?.CODE == 'DONE') ? (
                                <div>
                                    <span className='label-main'>Завершен</span>
                                </div>
                            ) : (
                                <div>
                                    <span className='label-main'>Выполняется</span>
                                </div>
                            ) }
                        </React.Fragment>

                    ) : (
                        <span className='color-main'>{orderItem.OFFERS_COUNT} предложений</span>
                    )}




                    <div className='title'>{orderItem.UF_DESCRIPTION}</div>

                        <div className='order-field'>
                            <div className='icon info'>
                                <OrderAddrSvg/>
                            </div>
                            <span>
                                Адрес<br />
                                {orderItem.UF_ADDRESS}
                            </span>
                        </div>
                        <div className='order-field'>
                            <div className='icon info'>
                                <OrderDateSvg/>
                            </div>
                            <span>
                                    {orderItem.CREATE_DATETIME}
                                </span>
                        </div>
                        {orderItem.UF_STATUS=='CANCEL' ? (
                            <div className='order-field'>
                                <div class='icon warning'>
                                    <OrderClosedSvg />
                                </div>
                                <div>
                                    <div><b>Закрыта</b></div>
                                    <div className='label'>Статус заявки</div>
                                </div>

                            </div>

                        ):(
                            <React.Fragment>
                            {(orderItem?.status?.CODE == 'DONE') ? (
                                <div className='order-field'>
                                    <div className='icon warning'>
                                        <OrderClosedSvg/>
                                    </div>
                                    <div>
                                        <div><b>Завершено</b></div>

                                    </div>

                                </div>
                            ) : (
                                <div className='order-field'>
                                    <div className='icon success'>
                                        <OrderOpenSvg/>
                                    </div>
                                    <div>
                                        <div><b>Открыта</b></div>
                                        <div className='label'>{orderItem?.status?.TITLE}</div>
                                    </div>
                                </div>
                            )}
                            </React.Fragment>
                        )}


                    <b>#{orderItem.ID}</b><br />
                </Link>

            </div>
        </div>
    );
}

export function Orders(props) {

    const [cancelComment, setCancelComment] = useState('');
    const [confirmCancel, setConfirmCancel] = useState(false);
    const [cancelConfirmed, setCancelConfirmed] = useState(false);
    const [orderList, updateOrderList] = useState({});
    const [isReady, updateReadyState] = useState(false);

    const apiUrl = 'https://api.promarena.ru/rest/?action=getOrderList&phone='+props.user.phone;

    if(!isReady) {
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                console.log('This is your data', data);
                if (data.success) {
                    console.log("success");
                    updateReadyState(true);
                    updateOrderList(data.orderList);
                }
            });
    }

    function onChangeCancelComment(event) {

        setCancelComment(event.target.value);
    }

    function confirmedCancelOrder(id) {
        const apiUrl = 'https://api.promarena.ru/rest/?action=cancelOrder&orderId='+id+'&comment='+cancelComment;
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                console.log('This is your data', data);
                updateReadyState(false);
                setCancelConfirmed(true);
            });
        console.log("cancel:"+id);


    }

    function cancelOrder(id) {

        setCancelComment('');
        setCancelConfirmed(false);
        setConfirmCancel(id);

    }

    return (
        <div className='page'>

            {(confirmCancel !== false) &&
                <React.Fragment>
                    <div className='popup-shadow' onClick={()=>setConfirmCancel(false)}></div>

                </React.Fragment>
            }

            <div className={(confirmCancel !== false) ? 'slider open' : 'slider' }>
                <div className='top'>
                    <div className='handle'></div>
                </div>
                {cancelConfirmed ? (
                    <React.Fragment>
                        <div className='content centered'>
                            <CancelConfirmedSvg />
                            <div className='title'>Заказ отменен!</div>
                            <p>Вам могут написать или позвонить, чтобы уточнить детали</p>
                        </div>
                        <div className='action'>
                            <a className='btn btn-main' onClick={()=>setConfirmCancel(false)}>Подтвердить</a>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className='content'>
                            <textarea className='input-comment'
                              placeholder='Опишите причину отмены'
                              onChange={onChangeCancelComment}>{cancelComment}</textarea>
                        </div>

                        <div className='action'>
                            {(cancelComment.length > 3) ?
                                (
                                    <a className='btn btn-main' onClick={()=>confirmedCancelOrder(confirmCancel)}>Подтвердить</a>
                                ) : (
                                    <a className='btn'>Подтвердить</a>
                                )}
                        </div>
                    </React.Fragment>
                )}

            </div>

            <div className='page-header'>
                <Link className='page-header-nav' to='/'>
                    <div className='nav-rounded-bg'>
                        <BackSvg />
                    </div>

                </Link>
                <div className='page-header-title'>
                    Текущий заказ
                </div>
                <div className='page-header-nav'>

                </div>
            </div>



            <div className='order-list'>
                {isReady ? (
                    <>
                        {orderList.length == 0 &&
                            <span>Заказов нет</span>
                        }

                        {orderList.map(orderItem => (
                            <OrderItem item={orderItem} onCanelOrder={() => cancelOrder(orderItem.ID)}/>
                        ))}
                    </>
                ) :(
                    <div>Загрузка...</div>
                )}
            </div>
        </div>
    )
}