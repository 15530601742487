import {Link} from "react-router-dom";
import {ReactComponent as BackSvg} from "../../images/back.svg";
import React, {useState} from "react";
import {useParams} from "react-router";

export function TechList(props) {

    let { tgId } = useParams();
    const [techList, updateTechList] = useState([]);
    const [isReady, updateReadyState] = useState(false);
    if(!isReady) {
        const apiUrl = 'https://api.promarena.ru/rest/?action=getTechList';
        const telegramId = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id;
        fetch(apiUrl,{
                method: 'POST',
                headers: new Headers(
                    {"Content-Type": "application/json",
                        "Accept":"application/json"}
                ),
                body: JSON.stringify(
                    {'telegramId': telegramId}
                )
                }
            )
            .then((response) => response.json())
            .then((data) => {
                console.log('This is your data', data);
                if (data.success) {
                    console.log("success");
                    console.dir(data.data.list);
                    updateReadyState(true);
                    updateTechList(data.data.list);
                }
            });
    }


    return (
        <div className='page telegram'>
            <div className='page-header'>
                <div className='page-header-nav' >


                </div>
                <div className='page-header-title'>
                    <span>Список техники</span>

                </div>
                <Link className='page-header-nav action-add' to={"/executor/add/"+tgId+"/"}>
                    Добавить
                </Link>
            </div>
            <div className='content-wrapper'>

                {isReady ?
                    (
                    <div className='tech-list'>
                        {techList.map(item =>
                            <Link className='tech-list-item' to={"/executor/item/"+item['ID']+"/"}>
                                <div>{item.UF_MARKA_MODEL}</div>
                                <div>{item.UF_REG_NUMBER}</div>
                                <div>{item.UF_PRICE}</div>
                            </Link>
                        )}
                    </div>
                    ) : (
                        <div>
                            загрузка...
                        </div>
                    )
                }


            </div>
            <div className="footer action">
                <div className="col">
                    <Link className='btn btn-main' to={"/executor/filter/"}>Фильтр заявок</Link>
                </div>
            </div>
        </div>
    );

}