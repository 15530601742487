import {Link} from "react-router-dom";
import {ReactComponent as BackSvg} from "../../images/back.svg";
import React from "react";
import {ReactComponent as CalendarSvg} from "../../images/calendar.svg";
import {InputSelector} from "../../components/InputSelector";

export function Documents(props) {
    return (
        <div className='page'>
            <div className='page-header'>
                <Link className='page-header-nav' to='/'>
                    <div className='nav-rounded-bg'>
                        <BackSvg />
                    </div>

                </Link>
                <div className='page-header-title'>
                   Документы

                </div>
                <div className='page-header-nav'>

                </div>
            </div>
            <div>
                нужны документы
            </div>
        </div>
    )
}