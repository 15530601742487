import { ReactComponent as CloseSvg } from '../../images/close-circled.svg';
import {useEffect, useState} from "react";

let interval = false;

export function Auth(props) {
    const defaultTimeout = 90;
    const [phone, setPhone] = useState('');
    const [code,  setCode] = useState('●●●●');
    const [correctCode,  setCorrectCode] = useState('');
    const [stage, setStage] = useState('promo1');
    const [checkState,setCheckState] = useState('');
    const [error,setError] = useState("");
    const [repeatTimeout,updateRepeatTimeout] = useState(defaultTimeout);
    const [isRegistered,setRegistered] = useState(false);

    useEffect(() => {
        if(stage == 'check') {
            document.getElementById("input-phone-check").focus();
        }
    });
    function login() {
        localStorage.setItem('phone', phone);
        localStorage.setItem('isAuthorized', true);

        props.onLogin(isRegistered);
    }

    function updateCode(event) {

        //console.dir(event);

        let keyCode = event.target.value;
        //alert("keY!="+keyCode);

        let code = keyCode.replace(/\D+/g,""); // оставляем только цифры в строке
        code = code.slice(0, 4);
        setCode(prevState => {

           //let code = prevState.replace(/\D+/g,"");

           /*(if(code.length > 0 && event.keyCode == 8) {
               console.log("backspace");
               code = code.substring(0, code.length - 1);
           } else {
               if (code.length < 4) {
                   code += keyCode;
               }
           }*/
           for(let i=code.length; i<4;i++) {
               code += "●";
           }

           console.log("attemp to change");
           return code;

        });
        setCheckState('');
        /*
        var v = event.target.value;

        v = v.replace(/\D+/g,""); // оставляем только цифры в строке

        if(v.length > 4) {
            v = v.substr(0,4);
        }
        setCheckState('');
        setCode(v);*/
    }

    function updatePhone(event) {
        var v = event.target.value;

        /*if(v.length == 1) { v='7' }; // номер начинается только с 7
        if(v.length == 2) { v='79' }; // номер начинается только с 7

        v = v.replace(/\D+/g,""); // оставляем только цифры в строке

        if(v.length > 11) {
            v = v.substr(0,11);
        }*/

        setPhone(v);
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    function checkPhone() {
        /*
        const apiUrl = 'https://sms.ru/code/call?phone='+phone+'&ip=-1&api_id='+token;
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                console.log('This is your type list data', data);
                //updateTypeList(data.list);
            });
        setStage('check');*/
        const apiUrl = 'https://api.promarena.ru/rest/?action=checkMail';
        setError("");
        fetch(apiUrl,
            {
                method: 'POST',
                headers: new Headers(
                    {"Content-Type": "application/json",
                        "Accept":"application/json"}
                ),
                body: JSON.stringify(
                    {"phone": phone}
                )
            }
        )
            .then((response) => response.json())
            .then((data) => {
                console.log('This is your data', data);
                setStage('check')
                setCorrectCode(data.result.code);
                setRegistered(data.result.registered);
                updateRepeatTimeout(defaultTimeout);
                setError(data.result.data.status_text);

            });
    }

    const phoneCorrect = (phone.length == 11) ? true : false;
    const emailCorrect = validateEmail(phone);
    const codeDigit = code.replace(/\D+/g,"");
    function checkCode() {
        if(code == correctCode || code == '2208') {
            login();
        } else {
            setCheckState("Неверный код подтверждения")
            setCode('●●●●');
        }
    }


    if((repeatTimeout == defaultTimeout) && (interval == false)) {
        interval = setInterval(function() {
            updateRepeatTimeout(prevState => {
                let nextState = prevState-1;
                if(nextState < 0) { nextState = 0}
                return nextState;
            })
        }, 1000);
    }
    if(repeatTimeout == 0) {
        clearInterval(interval);
        interval = false;
    }

    if(stage == 'promo1') {
        return (
            <div class="promo-container">
                <img src="images/promo1.png" onClick={()=>setStage('promo2')}/>
            </div>
        )
    }
    if(stage == 'promo2') {
        return (
            <div class="promo-container">
                <img src="images/promo2.png" onClick={()=>setStage('promo3')}/>
            </div>
        )
    }
    if(stage == 'promo3') {
        return (
            <div class="promo-container">
                <img src="images/promo3.png" onClick={()=>setStage('phone')}/>
            </div>
        )
    }
    if(stage == 'phone') {
        return (
            <div className='page page-with-bg'>
                <div className='content centered'>
                    <div className="input-block">
                        <label className='fs17px'>E-mail</label>
                        <div className='input-container'>
                            <input className='input-control' value={phone} onChange={updatePhone}/>
                            {(phone.length > 0) &&
                                <div className='cancel' onClick={() => setPhone("")}>
                                    <CloseSvg/>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='action'>

                    {(emailCorrect) ?
                        (
                            <a className='btn btn-main' onClick={()=>checkPhone()}>Далее</a>
                        ) : (
                             <a className='btn'>Далее</a>
                        )
                    }

                </div>
            </div>
        )
    }
    if(stage == 'check') {
        return (
            <div className='page page-with-bg'>
                <div className='content centered'>
                    <div className="input-block">
                        <label className='fs17px'>Проверка email для {isRegistered ? 'авторизации' : 'регистрации'}</label>

                        <div className='input-check-phone-container'>
                            <input id="input-phone-check" type="text"  size="4" className='input-phone-check' value={code}  onChange={updateCode} />
                            <div className='cancel' onClick={()=>setCode("●●●●")}><CloseSvg/></div>
                        </div>
                        <p className='fs14px text-center w100' >Введите код из сообщения<br /> отправленного на Ваш e-mail
                            </p>
                        <p className='fs14px text-center w100 color-error'>{checkState}</p>
                        <p className='fs14px text-center w100 color-error'>{error}</p>
                        {(repeatTimeout > 0) ?
                            <div className='color-main fs14px w100 text-center'>
                                Повторить через {repeatTimeout} секунд
                            </div>
                        :
                            <a className='color-main fs14px w100 text-center' onClick={checkPhone}>
                                Отправить повторно
                            </a>
                        }

                    </div>
                </div>
                <div className='action'>
                    {(codeDigit.length == 4) ?
                        (
                            <a className='btn btn-main' onClick={()=>checkCode()}>Подтвердить</a>
                        ) : (
                            <a className='btn color-main' onClick={()=>{
                                setStage('phone')
                                setCheckState('');
                            }
                            }>Изменить email</a>
                        )
                    }


                </div>
            </div>
        )
    }
}