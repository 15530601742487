import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import { ReactComponent as BackSvg } from '../../images/back.svg';
import {useParams} from "react-router";
import {ReactComponent as OrderDateSvg} from "../../images/order-date.svg";
import {ReactComponent as PaySvg} from "../../images/pay.svg";
import {ReactComponent as PayTotalSvg} from "../../images/pay-total.svg";
import {ReactComponent as PayRoadSvg} from "../../images/pay-road.svg";
import {ProfileBlockSm} from "../../components/ProfileBlockSm";

export function Offers(props) {

    let { orderId } = useParams();

    const [offerList, updateOfferList] = useState({});
    const [isReady, updateReadyState] = useState(false);
    const apiUrl = 'https://api.promarena.ru/rest/?action=getOfferList&orderId='+orderId;

    useEffect(() => {
        console.log("settEffect");
        props.setRefreshFunction(() => needReload);
    }, []);

    function needReload() {
        console.log("needReload");
        updateReadyState(false);
    }

    if(!isReady) {
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                console.log('This is your data', data);
                if (data.success) {
                    console.log("success");
                    updateReadyState(true);
                    updateOfferList(data.offerList);
                }
            });
    }

    return (
        <div className='page'>
            <div className='page-header'>
                <Link className='page-header-nav' to='/orders/'>
                    <div className='nav-rounded-bg'>
                        <BackSvg />
                    </div>

                </Link>
                <div className='page-header-title'>
                    Список предложений по заказу #{orderId}
                </div>
                <div className='page-header-nav'>

                </div>
            </div>
            <div className='offer-list'>
                {isReady && (offerList == 0) &&
                    <div>Поиск исполнителя...</div>
                }
                {isReady ? (
                    offerList.map(offerItem => (
                        <Link key={offerItem.ID} className='offer-item' to={"/offers/"+offerItem.UF_ORDER_ID+"/offer/"+offerItem.ID+"/"}>
                            <div className='row'>
                                <div className='col'>
                                    <span className='color-main'>Перейти к предложению #{offerItem.ID}</span>
                                </div>
                                <div className='col justify-content-end'>
                                    {(offerItem.CREATE_DATETIME != false) &&
                                        <span>{offerItem.CREATE_DATETIME}</span>
                                    }
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className='order-field'>
                                        <div className='icon success'>
                                            <PaySvg />
                                        </div>
                                        <div className='content'>
                                            <span className='value'>{offerItem.PRICE}</span>
                                            <span className='label'>Стоимость работы</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='order-field'>
                                        <div className='icon info'>
                                            <PayRoadSvg/>
                                        </div>
                                        <div className='content'>
                                            <span className='value'>{offerItem.ROAD_PRICE}</span>
                                            <span className='label'>Стоимость перегона</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col'>
                                    <ProfileBlockSm profile={offerItem.EXECUTOR} />
                                </div>
                                <div className='col '>
                                    <div className='order-field'>
                                        <div className='icon success'>
                                            <PayTotalSvg />
                                        </div>
                                        <div className='content'>
                                            <span className='value'>{offerItem.TOTAL_PRICE}</span>
                                            <span className='label'>Общая стоимость</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Link>
                    ))
                ) :(
                    <div>Загрузка...</div>
                )}
            </div>
        </div>
    );
}