import './style.css';
import { ReactComponent as EditSvg } from '../../images/edit.svg';

export function InputSelector(props) {
    return (
        <div className='input-selector'>
            <div className='icon'>
                {props.icon}
            </div>
            <div className='body'>
                <div className='label'>{props.title}</div>

                <input type={props.type} placeholder='Ввести' onChange={props.onChange}/>
            </div>
            <div className='edit-icon'>
                <EditSvg />
            </div>

        </div>
    )
}

export function InputField(props) {
    return (
        <div className='input-field'>
            <input type={props.type} value={props.value} placeholder={props.title} onChange={props.onChange}/>
        </div>
    )
}