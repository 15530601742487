import {Link} from "react-router-dom";
import {ReactComponent as BackSvg} from "../../images/back.svg";
import React from "react";
import {ReactComponent as CalendarSvg} from "../../images/calendar.svg";
import {InputSelector} from "../../components/InputSelector";

export function About(props) {
    return (
        <div className='page'>
            <div className='page-header'>
                <Link className='page-header-nav' to='/'>
                    <div className='nav-rounded-bg'>
                        <BackSvg />
                    </div>

                </Link>
                <div className='page-header-title'>
                    О приложении

                </div>
                <div className='page-header-nav'>

                </div>
            </div>
            <div>
                <img class="logo-about" src="https://promarena.ru/images/logo.jpg" />
                <p><b>@ПромАрена</b><br />Все права защищены.</p>

            </div>
            <div className="left-menu-items">
                <Link to="/about/agreement/">Пользовательское соглашение</Link>
                <Link to="/about/faq/">Вопросы ответы</Link>
                <Link to="/about/rekv/">Контакты и реквизиты</Link>
            </div>
        </div>
    )
}