import logo from './logo.svg';
import './App.css';
import './css/colors.css';
import './css/styles.css';
import {Auth} from "./components/Auth";
import {Home} from "./modules/Home";
import {Orders} from "./modules/Orders";
import {Offers} from "./modules/Offers";
import {OfferItem} from "./modules/OfferItem";
import {OrderItem} from "./modules/OrderItem";
import {Component, useEffect, useRef, useState} from "react";
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import {Route, Routes, useHistory} from "react-router-dom";
import {NotifyItem} from "./components/NotifyItem";
import {Support} from "./modules/Support";
import {Personal} from "./modules/Personal";
import {Balance} from "./modules/Balance";
import * as PropTypes from "prop-types";
import {History} from "./modules/History";
import {Documents} from "./modules/Documents";
import {About} from "./modules/About";
import {useLocation, useNavigate} from "react-router";
import {TechList} from "./modules/TechList";
import {TechListItem} from "./modules/TechListItem";
import {TechListAdd} from "./modules/TechListAdd";
import {Settings} from "./modules/Settings";
import {AboutRekv} from "./modules/AboutRekv";
import {AboutFAQ} from "./modules/AboutFAQ";
import {AboutAgreement} from "./modules/AboutAgreement";
import {ExecutorFilter} from "./modules/ExecutorFilter";



let subscriptionId = false;


function App() {
    const navigate = useNavigate();
    const location = useLocation();

    const [user, updateUserState] = useState(getUserState);
    const [userData, updateUserData] = useState(false);
    const [offersRefreshFunction, setOffersRefreshFunction] = useState(null);

    let pushList = (localStorage.getItem("push") != null) ? JSON.parse(localStorage.getItem("push")) : [];
    console.dir(pushList);
    const [notifyList, updateNotifyList] = useState(pushList);

    function onNewEvent(event) {
        let notifyItem = {
            title: event.data.title,
            text: event.data.text,
            link: event.data.link,
            time: event.data.time,
            actionOk: ((event.data.actionOk != undefined) && (event.data.actionOk.length > 0)) ? event.data.actionOk : false,
            actionOkData: ((event.data.actionOkData != undefined) && (event.data.actionOk.length > 0)) ? event.data.actionOkData : false,
        }

        console.log("onNewEvent");
        console.dir(event);
        /*updateNotifyList(prevArray => {
            notifyItem.id = prevArray.length;
            let newArray = [...prevArray, notifyItem];
            return newArray;
        });*/
        const currentUrl = window.location.href.replace(window.location.origin, '');
        console.log("test:" + currentUrl + "=" + event.data.link);
        console.log("types=" + typeof (currentUrl) + "=" + typeof (event.data.link));
        if (currentUrl.indexOf(event.data.link) === 0) {
            console.log("update:" + currentUrl + "=" + event.data.link);
            window.location.reload();
            //offersRefreshFunction();
        } else {
            updateNotifyList(prevArray => {
                notifyItem.id = prevArray.length;
                let newArray = [...prevArray, notifyItem];
                return newArray;
            });
        }
    }

    function handlerReadPush(id, link) {
        console.log("handlerReadPush:" + id);
        updateNotifyList(prevArray => {
            let newArray = [];
            prevArray.map((item) => {
                if ((item.id !== id)
                    &&
                    ((link === false) || (link != item.link))
                ) {
                    newArray.push(item);
                }

            })

            console.dir(newArray);
            return newArray;
        })
    }

    localStorage.setItem("push", JSON.stringify(notifyList));

    console.log("notifyList");
    console.dir(notifyList);


    useEffect(() => {

        /* eslint-disable */
        /**
         }
         * Подписываемся на получение сообщения из канала Pipe_name
         */
        if (subscriptionId == false) {
            console.log("events comet");

            subscriptionId = CometServer().subscription("promarena_" + user.phone, function (event) {
                console.log(event);
                //$("#textHolder").html( $("#textHolder").html() +"<hr>"+event.data.text);
                onNewEvent(event);
            })

            cometApi.onAuthSuccess(function () {
                console.log("Подключились и авторизовались успешно")
            })

            // Добавление callBack функции на уведомление об не успешной авторизации
            cometApi.onAuthFalill(function () {
                console.log("Подключились успешно но не авторизовались")
            })

            /**
             * Подключение к комет серверу. Для возможности принимать команды.
             * dev_id ваш публичный идентифиукатор разработчика
             */
            CometServer().start({dev_id: 3988})
            /* eslint-enable */
        }

        // get User details
        if (user.isAuthorized && (user.loaded === false)) {
            console.log("get user data");
            const apiUrl = 'https://api.promarena.ru/rest/?action=getUser';
            fetch(apiUrl, {
                    method: 'POST',
                    headers: new Headers(
                        {
                            "Content-Type": "application/json",
                            "Accept": "application/json"
                        }
                    ),
                    body: JSON.stringify(
                        {'userPhone': user.phone}
                    )
                }
            )
                .then((response) => response.json())
                .then((res) => {
                    console.log('This is your user ' + user.phone + ' data', res);
                    if (res.ok) {
                        updateUserState((prevState) => {
                            let newState = {...prevState};
                            newState.name = res['data'].UF_NAME;
                            newState.nameSecond = res['data'].UF_NAME_SECOND;
                            newState.mail = res['data'].UF_MAIL;
                            newState.loaded = true;
                            /*console.log("new State user:");*/
                            console.dir(newState);
                            /* console.dir(res);*/
                            return newState;
                        });
                    } else {
                        console.log("unregistered!");
                        updateUserState((prevState) => {
                            let newState = {...prevState};
                            newState.loaded = true;
                            newState.unReg = true;

                            return newState;
                        });

                    }
                });
        }
    })


    function getUserState() {
        let user = {
            'phone': localStorage.getItem("phone"),
            'isAuthorized': localStorage.getItem("isAuthorized"),
            'loaded': false,

        }
        return user;
    }

    function onUserLogin(isRegistered) {
        if (isRegistered) {
            navigate('/personal/');
        }
        let user = {
            'phone': localStorage.getItem("phone"),
            'isAuthorized': localStorage.getItem("isAuthorized"),
            'loaded': false
        }
        updateUserState(user);

    }


    console.dir(user);
    if (location.pathname.includes("executor")) {



        return (
            <div className="App">
                <Routes>
                    <Route path='/executor/list/' element={<TechList/>}/>
                    <Route path='/executor/list/:tgId/' element={<TechList/>}/>
                    <Route path='/executor/add/' element={<TechListAdd/>}/>
                    <Route path='/executor/add/:tgId/' element={<TechListAdd/>}/>
                    <Route path='/executor/item/:itemId/' element={<TechListItem/>}/>
                    <Route path='/executor/item/:itemId/:tgId/' element={<TechListItem/>}/>
                    <Route path='/executor/filter/' element={<ExecutorFilter/>}/>
                </Routes>

            </div>
        )
    } else {
            if (user.isAuthorized) {

                if ((user.loaded === true) && (user?.unReg === true)) {
                    return (
                        <div className="App">
                            <Personal user={user} reg={true} title='Регистрация'/>
                        </div>
                    )
                } else {


                    return (
                        <div className="App">
                            <Home user={user}/>
                            <div className={location.pathname == "/" ? "subpages" : "subpages open"}>
                                <Routes>
                                    <Route exact path='/personal/' element={<Personal user={user}/>}/>
                                    <Route exact path='/personal/balance/' element={<Balance user={user}/>}/>
                                    <Route exact path='/personal/history/' element={<History user={user}/>}/>
                                    <Route exact path='/docs/' element={<Documents user={user}/>}/>
                                    <Route exact path='/about/' element={<About user={user}/>}/>
                                    <Route exact path='/about/rekv/' element={<AboutRekv user={user}/>}/>
                                    <Route exact path='/about/agreement/' element={<AboutAgreement user={user}/>}/>
                                    <Route exact path='/about/faq/' element={<AboutFAQ user={user}/>}/>


                                    <Route path='/orders' element={<Orders user={user}/>}/>
                                    <Route path='/order/:orderId/' element={<OrderItem user={user} selectedTab='detail'/>}/>
                                    <Route path='/order/:orderId/payment/'
                                           element={<OrderItem user={user} selectedTab='payment'/>}/>
                                    <Route path='/order/:orderId/status/'
                                           element={<OrderItem user={user} selectedTab='status'/>}/>
                                    <Route path='/order/:orderId/review/'
                                           element={<OrderItem user={user} selectedTab='review'/>}/>
                                    <Route path='/offers/:orderId/offer/:offerId' element={<OfferItem user={user}/>}/>
                                    <Route path='/offers/:orderId' element={<Offers
                                        setRefreshFunction={(f) => {
                                            setOffersRefreshFunction(f);
                                        }}
                                        user={user}/>}/>
                                    <Route path='/support/' element={<Support user={user}/>}/>
                                    <Route path='/support/:orderId/' element={<Support user={user}/>}/>
                                    <Route path='/settings/' element={<Settings user={user}/>}/>
                                </Routes>
                            </div>
                            <div className='notify-list'>
                                {notifyList.map((item) => (
                                    <NotifyItem key={item.id} data={item} onRead={(id, link) => handlerReadPush(id, link)}/>
                                ))}
                            </div>
                        </div>
                    );
                }
            } else {
                return (
                    <div className="App">
                        <Auth onLogin={onUserLogin}/>
                    </div>
                );
            }


        }
    }

export default App;
